// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="p-4 text-center bg-gray-100 dark:bg-gray-800">
      &copy; {new Date().getFullYear()} mohcinedesigns. All rights reserved.
    </footer>
  );
};

export default Footer;
