// src/components/MainContent.js
import React from 'react';

const MainContent = () => {
  return (
    <main className="flex-grow p-8 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-5xl font-bold mb-6">Welcome to mohcinedesigns</h2>
        <p className="text-xl leading-relaxed">
          Discover the latest trends in design and embrace the elegance of dark mode.
        </p>
      </div>
    </main>
  );
};

export default MainContent;
