// src/components/Header.js
import React, { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';
import { SunIcon, MoonIcon } from '@heroicons/react/24/solid'; // Updated import

const Header = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <header className="p-4 flex justify-between items-center bg-gray-100 dark:bg-gray-800">
      <h1 className="text-2xl font-bold">mohcinedesigns</h1>
      <button
        onClick={toggleTheme}
        className="p-2 rounded focus:outline-none"
        aria-label="Toggle Dark Mode"
      >
        {theme === 'dark' ? (
          <SunIcon className="h-6 w-6 text-yellow-500" />
        ) : (
          <MoonIcon className="h-6 w-6 text-gray-800" />
        )}
      </button>
    </header>
  );
};

export default Header;
